import React from "react"

import type { CreateAccountPagePropsType } from "../components/CreateAccount/CreateAccountAssets"

import CreateAccount from "../components/CreateAccount/CreateAccountPage"
import { PublicRoute } from "@lesmills/gatsby-theme-common"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"
import { sessionReceiverURL } from "../../../gatsby-theme-common/src/services/amplifyStorage"

const CreateAccountPage = ({
  location,
  pageContext,
  data,
}: CreateAccountPagePropsType) => {
  const lang = pageContext.unPublishedLang || pageContext.lang
  const {
    prismicLayout = {},
    prismicCreateAccountPage = {},
    prismicUpdatePaymentDetailsPage = {},
    prismicPromotionPages = {},
  } = data || {}
  if (!data) {
    return null
  }

  return (
    <>
      <PublicRoute
        component={props =>
          CreateAccount(
            prismicCreateAccountPage.data,
            prismicLayout.data,
            prismicUpdatePaymentDetailsPage.data,
            lang,
            location,
            false,
            prismicPromotionPages.data,
            props
          )
        }
        lang={lang}
        location={location}
      />


      {process.env.GATSBY_RT_26_07_2022_SYNC_SESSION === 'true' &&
        sessionReceiverURL && (
          <iframe
            title="session_receiver"
            id="session_receiver"
            src={sessionReceiverURL}
            width="0"
            height="0"
          />
      )}
    </>
  )
}

export const query = graphql`
  query($lang: String) {
    prismicPromotionPages(lang: { eq: $lang }) {
      ...PromotionPages
    }
    prismicLayout(lang: { eq: $lang }) {
      ...LayoutCreateAccountPage
    }
    prismicCreateAccountPage(lang: { eq: $lang }) {
      ...CreateAccountPage
    }
    prismicUpdatePaymentDetailsPage(lang: { eq: $lang }) {
      ...UpdatePaymentDetailsPage
    }
  }
`

export default withPreview(CreateAccountPage)
